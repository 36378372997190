import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';
import {signup} from '../../../Axios/Axios'


class SignupForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            name : 'Abhishek',
            email : 'abhishekyagnik@gmail.com',
            mobile : '9406588701',
            password: '123',
            alertTitle : '',

        }
        this.updateMobileNumber = this.updateMobileNumber.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updateName = this.updateName.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.validate = this.validate.bind(this);
        this.epErrorName = React.createRef();
        this.epErrorMobile = React.createRef();
        this.epErrorEmail = React.createRef();
        this.epErrorPass = React.createRef();
        this.epMobile = React.createRef();
        this.epEmail = React.createRef();
        this.epPassword = React.createRef();
    }

    componentDidMount(){
    }

    updatePassword(e){
        this.setState({password : e.target.value});

    }
   
    updateMobileNumber(e){
        this.setState({mobile : e.target.value});
    }

    updateEmail(e){
        this.setState({email : e.target.value});
    }
    updateName(e){
        this.setState({name : e.target.value});
    }

    validate(e){
        e.preventDefault();
        let name = this.state.name;
        let email = this.state.email;
        let mobile = this.state.mobile;
        console.log(mobile);
        let password = this.state.password;
        if(/^\d{10}$/.test(mobile)){
            // console.log(mobile);
            localStorage.setItem('mobile', mobile);
            this.epErrorName.current.textContent = '';
            this.epErrorEmail.current.textContent = '';
            this.epErrorMobile.current.textContent = '';
            this.epErrorPass.current.textContent = '';
            signup(name, email, mobile, password, (response, error) => {
                console.log(response, error)
                if(response == 'error'){
                    this.setState({
                        errorMsg : error,
                        errorSalut : 'Error'
                    });
                    this.epErrorMobile.current.textContent = error.mobile?error.mobile[0]:'';
                    this.epErrorName.current.textContent = error.name?error.name[0]:'';
                    this.epErrorEmail.current.textContent = error.email?error.email[0]:'';
                    this.epErrorPass.current.textContent = error.pass?error.pass[0]:'';
                }else{
                    // this.props.history.push(Constants.ROUTES.ADMIN_DASHBOARD);
                    this.props.history.push('/validate-mobile');
                }
            })
        }else{
            this.epErrorMobile.current.textContent = 'Invalid Mobile Number';
            return false;
        }
    }


    render(){
        return(
            <div class="container">
            <div class="login-container">
                <div class=" d-box">
                    <div class="text-center">
                        <h3>Signup</h3>
                        <span class="color-grey">Create a new account</span>
                    </div>
                    <br />
                    <br />
                    <form class="d-form" action="" onSubmit={this.validate}>
                    <div class="form-group">
                            <input type="text" class="form-control"  type="text" placeholder="Full Name" name="name" autocomplete="off"
                                              ref={this.epName}
                                              onChange={this.updateName}
                                              autofocus
                                              value={this.state.name}
                                              />
                            <span class="ep-error form-control-feedback" ref={this.epErrorName}></span>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control"  type="text" placeholder="Email" name="email" autocomplete="off"
                                              ref={this.epEmail}
                                              onChange={this.updateEmail}
                                              value={this.state.email}
                                              />
                            <span class="ep-error form-control-feedback" ref={this.epErrorEmail}></span>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control"  type="text" placeholder="Mobile" name="mobile" autocomplete="off"
                                              ref={this.epMobile}
                                              onChange={this.updateMobileNumber}
                                              maxLength="10"
                                              value={this.state.mobile}
                                              maxlength="10" />
                            <span class="ep-error form-control-feedback" ref={this.epErrorMobile}></span>
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" type="password" placeholder="Password" name="password"
                                ref={this.epPassword}
                                onChange={this.updatePassword}
                                value={this.state.password}
                                />
                                <span class="ep-error form-control-feedback" ref={this.epErrorPass}></span>
                        </div>
                        <div class="ui checkbox">
                            <input type="checkbox" name="" />
                            <label class="form-check-label" for="gridCheck">
                            <p>Agree to Terms &amp; Conditions</p>
                            </label>
                        </div>
                        <input type="submit" class="btn d-btn btn-primary full-width" value="Signup" name="" />
                    </form>
                    <br />
                    <div class="text-center">
                        <br />
                        <p class="color-grey">Already have an account? <Link to="/login" class="d-link"> Login to your account</Link></p>
                    </div>
                </div>
            </div>
    </div>
        );
    }
}

export default SignupForm;