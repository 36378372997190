import React, {Component} from 'react';
import HeaderLoggedIn from '../common/HeaderLoggedIn';
import Footer from '../common/Footer';
import AdminMenu from '../admin/AdminMenu'
import { Link, Redirect } from 'react-router-dom';
// import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';


class Dashboard extends Component{

    constructor(props){
        super(props)
    }

    componentDidMount(){
        // $("body").hide();
        $("body").on('mouseover', '.m-portlet__nav-link', function(){
            $('.m-dropdown').addClass('m-dropdown--open');
        })
        $("body").on('blur', '.m-portlet__nav-link', function(){
            $('.m-dropdown').removeClass('m-dropdown--open');
        });
    }
    render(){
        // if (isAuthorized()) {
        //     // return <Redirect to='/offers' />
        // }
        return(
            <div>
                <div class="m-grid m-grid--hor m-grid--root m-page">
                    <HeaderLoggedIn />
                    <div class="m-grid__item m-grid__item--fluid m-grid m-grid m-grid--hor m-container m-container--responsive m-container--xxl">
                        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--hor-desktop m-grid--desktop m-body">
                        <AdminMenu active={this.props.selectedMenuItem}/>
                            <div class="m-grid m-grid--hor m-grid--root m-page">
                                <div class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-body__content">
                                    <div class="m-grid__item m-grid__item--fluid m-wrapper">
                                        <div class="m-subheader ">
                                            <div class="d-flex align-items-center">
                                                <div class="mr-auto">
                                                    <h3 class="m-subheader__title ">{this.props.pageTitle}</h3>
                                                </div>
                                            { this.props.extraMenu && (this.props.extraMenu.length > 1) &&
                                            <div>
                                                <div class="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push" m-dropdown-toggle="hover" aria-expanded="true">
                                                    <a href="#" class="m-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle">
                                                        <i class="la la-plus m--hide"></i>
                                                        <i class="la la-ellipsis-h"></i>
                                                    </a>
                                                    <div class="m-dropdown__wrapper" style={{zIndex: 101}}>
                                                        <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style={{left: 'auto', right: '21.5px'}}></span>
                                                        <div class="m-dropdown__inner">
                                                            <div class="m-dropdown__body">
                                                                <div class="m-dropdown__content">
                                                                    <ul class="m-nav">
                                                                        <li class="m-nav__section m-nav__section--first m--hide">
                                                                            <span class="m-nav__section-text">Quick Actions</span>
                                                                        </li>
                                                                        <li class="m-nav__item">
                                                                            <Link to={this.props.extraMenu} class="m-nav__link">
                                                                                <i class="m-nav__link-icon flaticon-plus"></i>
                                                                                <span class="m-nav__link-text">Add New</span>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                            
                                            </div>
                                            }
                                    </div>
                                </div>

                                        <div class="m-content">
                                            <div class="row">
                                                <div className={this.props.tableSize?'col-md-'+this.props.tableSize:'col-md-8'}>
                                                    <div class="m-portlet m-portlet--full-height m-portlet--tabs   m-portlet--rounded" id="">
                                                      {this.props.children}
                                                    </div>     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
			        </div>
                    <Footer />
		        </div>
                <div id="m_scroll_top" class="m-scroll-top">
                    <i class="la la-arrow-up"></i>
                </div>
            </div>
        );
    }
}

export default Dashboard;