import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';
import {login} from '../../../Axios/Axios'


class ForgotForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            mobile : '',
            password: '',
            alertTitle : ''
        }
        this.updateMobileNumber = this.updateMobileNumber.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.validateMobileNumber = this.validateMobileNumber.bind(this);
        this.epError = React.createRef();
        this.epMobile = React.createRef();
        this.epPassword = React.createRef();
    }

    componentDidMount(){
    }

    updatePassword(e){
        this.setState({password : e.target.value});

    }
   
    updateMobileNumber(e){
        this.setState({mobile : e.target.value});
    }

    validateMobileNumber(e){
        e.preventDefault();
        let mobile = this.state.mobile;
        console.log(mobile);
        let password = this.state.password;
        if(/^\d{10}$/.test(mobile)){
            // console.log(mobile);
            localStorage.setItem('mobile', mobile);
            this.epError.current.textContent = '';
            login(mobile, password, (response) => {
                console.log(response)
                this.props.history.push('/add-godown');
            })
        }else{
            this.epError.current.textContent = 'Invalid Mobile Number';
            return false;
        }
    }


    render(){
        return(
            <div class="container">
            <div class="login-container">
                <div class=" d-box">
                    <div class="text-center">
                        <h3>Forget Password</h3>
                        <span class="color-grey">Enter your mobile number to reset password</span>
                    </div>
                    <br />
                    <br />
                    <form class="d-form" action="" onSubmit={this.handleFormSubmit}>
                        <div class="form-group">
                            <input type="text" class="form-control"  type="text" placeholder="Mobile" name="mobile" autocomplete="off"
                                              ref={this.epMobile}
                                              onChange={this.updateMobileNumber}
                                              autofocus
                                              maxLength="10"
                                              maxlength="10" />
                            <span class="ep-error form-control-feedback" ref={this.epError}></span>
                        </div>
                        <input type="submit" class="btn d-btn btn-primary full-width" value="Recover Password" name="" />
                    </form>
                    <br />
                    <div class="text-center">
                        <br />
                        <p class="color-grey"><Link to="/login" class="d-link"> Go Back </Link></p>
                    </div>
                </div>
            </div>
    </div>		
        );
    }
}

export default ForgotForm;