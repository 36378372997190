module.exports = {
    ROUTES : {
        ADD_USER: '/users/add',
        LIST_USER: '/users',
        EDIT_USER: '/users/edit/:id',
        ADMIN_DASHBOARD: '/dashboard',
    },
    ROLES_PERMISSIONS : {
        USER_SUPERADMIN : [
            'godown', 'user', 'item', 'inventory', 'technician-inventory', 'technician', 'support', 'technician-sale'
        ],
        USER_GODOWN : [
            'godown','inventory', 'technician-inventory', 'technician'
        ],
        USER_SUPPORT : [
            'support','technician-sale'
        ],
    }
};