import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';


class Header extends Component{

    componentDidMount(){
    }
    render(){
       
        return(
           <header></header>
        );
    }
}

export default Header;