import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';


class HeaderLoggedIn extends Component{

    constructor(props){
        super(props);
        this.state = {
            name : ''
        }
    }

    componentDidMount(){
        this.setState({
            'name' : localStorage.getItem('ezname')
        })
    }
    render(){
       
        return(
            <header id="m_header" class="m-grid__item	m-grid m-grid--desktop m-grid--hor-desktop  m-header ">
            <div class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--hor-desktop m-container m-container--responsive m-container--xxl">
                <div class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-header__wrapper">

                    <div class="m-grid__item m-brand">
                        <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                            <div class="m-stack__item m-stack__item--middle m-brand__logo">
                                <Link to="/" class="m-brand__logo-wrapper">
                                    <img alt="" src="http://placehold.it/50x35" />
                                </Link>
                            </div>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <div class="m-stack__item m-stack__item--middle m-brand__tools text-left">
                                <h5 class="m--font-light m--font-transform-u">Goyal Enterprises</h5>
                            </div>
                        </div>
                    </div>

                    <div class="m-grid__item m-grid__item--fluid m-header-head" id="m_header_nav">
                        <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                            <div class="m-stack__item m-topbar__nav-wrapper">
                                <ul class="m-topbar__nav m-nav m-nav--inline">
                                    <li class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light">
                                        <a href="#" class="m-nav__link m-dropdown__toggle">
                                            <span class="m-topbar__welcome m--hidden-tablet m--hidden-mobile">Hello,&nbsp;</span>
                                            <span class="m-topbar__username m--hidden-tablet m--hidden-mobile m--padding-right-15"><span class="m-link">{this.state.name}</span></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>
        );
    }
}

export default HeaderLoggedIn;