import React, {Component} from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import LoginForm from './LoginForm';
import { Link, Redirect } from 'react-router-dom';
// import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';


class Logout extends Component{
    constructor(props){
        super(props);
    }

    componentDidMount(){
        localStorage.removeItem('eztoken');
        localStorage.removeItem('ezroles');
        this.props.history.push('/');
    }
    render(){
      return(
          <span></span>
      );
    }
}

export default Logout;