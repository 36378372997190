import React, {Component} from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import LoginForm from './site/login/LoginForm';
import { Link, Redirect } from 'react-router-dom';
// import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';
// import AdminDashboard from './admin/AdminDashboard';
import Dashboard from './layouts/Dashboard'
import Constants from '../Utilities/Constants'
import {getStats} from '../Axios/Axios'


class AdminDashboard extends Component{

    constructor(props){
        super(props);
        this.state = {
            stats : '',
            items : ''

        }
    }

    componentDidMount(){
        $(".ep-transaction-link").hide();
        this.loadStats();
    }

    loadStats(){
        getStats((response,data ) => {
            console.log(data.stats);
            if(data.stats){
                this.setState({
                    stats : data.stats,
                    items : data.stats.items
                });
            }
        })
    }
    render(){
        // if (isAuthorized()) {
        //     // return <Redirect to='/offers' />
        // }

        return(
            <Dashboard pageTitle="Dashboard" extraMenu={Constants.ROUTES.ADD_GODOWN} selectedMenuItem="Dashboard">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="">
                                            <div class="m-portlet__body">
                                            <div class="m-widget4 m-widget4--chart-bottom">
                                                    <div class="m-widget4__item">
                                                        <div class="m-widget4__ext">							 
                                                            <a href="#" class="m-widget4__icon m--font-brand">
                                                                <i class="flaticon-interface-3"></i>
                                                            </a>
                                                        </div>
                                                        <div class="m-widget4__info">
                                                            <Link to={Constants.ROUTES.LIST_ITEM} class="m-widget4__text">
                                                            Total Items
                                                            </Link> 							 		 
                                                        </div>
                                                        <div class="m-widget4__ext">    
                                                            <Link to={Constants.ROUTES.LIST_ITEM} class="m-widget4__number m--font-accent">{this.state.items?this.state.items:'-'}</Link>
                                                        </div>
                                                    </div>
                                                    <div class="m-widget4__item">
                                                        <div class="m-widget4__ext">							 
                                                            <a href="#" class="m-widget4__icon m--font-brand">
                                                            <i class="flaticon-folder-4"></i>
                                                            </a>
                                                        </div>
                                                        <div class="m-widget4__info">
                                                            <span class="m-widget4__text">
                                                           Items with technicians
                                                            </span> 							 		 
                                                        </div>
                                                        <div class="m-widget4__ext">
                                                            <span class="m-widget4__stats m--font-info">
                                                                <span class="m-widget4__number m--font-accent">-</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="m-widget4__item">
                                                        <div class="m-widget4__ext">							 
                                                            <a href="#" class="m-widget4__icon m--font-brand">
                                                            <i class="flaticon-line-graph"></i>
                                                            </a>
                                                        </div>
                                                        <div class="m-widget4__info">
                                                            <span class="m-widget4__text">
                                                            Pending Complaints
                                                            </span> 							 		 
                                                        </div>
                                                        <div class="m-widget4__ext">
                                                            <span class="m-widget4__stats m--font-info">
                                                                <span class="m-widget4__number m--font-accent">-</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="m-widget4__item m-widget4__item--last">
                                                        <div class="m-widget4__ext">							 
                                                            <a href="#" class="m-widget4__icon m--font-brand">
                                                            <i class="flaticon-diagram"></i>
                                                            </a>
                                                        </div>
                                                        <div class="m-widget4__info">
                                                            <span class="m-widget4__text">
                                                            Total unpaid amount
                                                            </span> 							 		 
                                                        </div>
                                                        <div class="m-widget4__ext">
                                                            <span class="m-widget4__stats m--font-info">
                                                                <span class="m-widget4__number m--font-accent">-</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
            </Dashboard>
        );
    }
}

export default AdminDashboard;