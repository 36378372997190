import React, { Component } from 'react';

import Home from './Home';
import Constants from '../Utilities/Constants'
import Login from './site/login/Login';
import Logout from './site/login/Logout';
import Signup from './site/signup/Signup';
import Forgot from './site/forgot/Forgot';
import ValidateEmail from './site/validate/Email';
import ValidateMobile from './site/validate/Mobile';
import AdminDashboard from './AdminDashboard';


import { Route, Switch } from 'react-router-dom';

class View extends Component {
    render() {
        return (
            <div className="body">
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/signup" exact component={Signup} />
                    <Route path="/forgot" exact component={Forgot} />
                    <Route path="/dashboard" exact component={AdminDashboard} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/validate-mobile" exact component={ValidateMobile} />
                    <Route path="/validate-email" exact component={ValidateEmail} />
                    {/* <Route exact path="/details/:id" render={(props)=>{
                         <DetailsPage id={props.match.params.id}/>
                     }} /> */}
                </Switch>
            </div>
        );
    }
}

export default View;