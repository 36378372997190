import React, {Component} from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import SignupForm from './SignupForm';
import { Link, Redirect } from 'react-router-dom';
// import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';


class Signup extends Component{

    componentDidMount(){
        $(".ep-transaction-link").hide();
        document.title = "Signup";
    }
    render(){
        // if (isAuthorized()) {
        //     // return <Redirect to='/offers' />
        // }
        return(
            <div class="">
                <Header />
                        <SignupForm history={this.props.history}/>
                <Footer />
            </div>
        );
    }
}

export default Signup;