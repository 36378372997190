import React, {Component} from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import EmailForm from './EmailForm';
import { Link, Redirect } from 'react-router-dom';
// import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';


class Forgot extends Component{

    componentDidMount(){
        $(".ep-transaction-link").hide();
        document.title = "Validate Email";
    }
    render(){
        // if (isAuthorized()) {
        //     // return <Redirect to='/offers' />
        // }
        return(
            <div class="">
                <Header />
                        <EmailForm history={this.props.history}/>
                <Footer />
            </div>
        );
    }
}

export default Forgot;