import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';
import {login} from '../../../Axios/Axios'
import Constants from '../../../Utilities/Constants'
import { Header } from 'semantic-ui-react';


class LoginForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            mobile : '9406588700',
            password: '123456',
            alertTitle : '',
            errorMsg: '',

        }
        this.updateMobileNumber = this.updateMobileNumber.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.validateMobileNumber = this.validateMobileNumber.bind(this);
        this.epError = React.createRef();
        this.epErrorPass = React.createRef();
        this.epMobile = React.createRef();
        this.epPassword = React.createRef();
    }

    componentDidMount(){
    }

    updatePassword(e){
        this.setState({password : e.target.value});
    }
   
    updateMobileNumber(e){
        var reg = /^\d+$/;
        if(reg.test(e.target.value)){
            this.setState({mobile : e.target.value});
        }else{
            if(this.state.mobile.length > 1){
                e.target.value= this.state.mobile;
            }
        }
    }

    validateMobileNumber(e){
        e.preventDefault();
        let mobile = this.state.mobile;
        console.log(mobile);
        let password = this.state.password;
        this.epError.current.textContent = '';
        this.epErrorPass.current.textContent = '';
        if(!/^\d{10}$/.test(mobile)){
            // console.log(mobile);
            this.epError.current.textContent = 'Invalid Mobile Number';
            return false;
        }
        if(password.length < 1){
            this.epErrorPass.current.textContent = 'Please enter password';
            return false;
        }
        localStorage.setItem('mobile', mobile);
        login(mobile, password, (response, error) => {
            console.log(response, error.token)
            if(response == 'error'){
                this.setState({
                    errorMsg : error,
                    errorSalut : 'Error'
                });
            }else{
                window.location.href = error.site+error.token;
                // this.props.history.push(Constants.ROUTES.ADMIN_DASHBOARD);
            }
        });
    }


    render(){
        return(
            <div class="container">
                <div class="login-container">
                    <div class=" d-box">
                        <div class="text-center">
                            <h3>Login</h3>
                            <span class="color-grey">Login to your account</span>
                        </div>
                        <br />
                        <br />
                        <form class="d-form" action="" onSubmit={this.validateMobileNumber}>
                            <div class="form-group">
                                <input type="text" class="form-control"  type="text" placeholder="Mobile" name="mobile" autocomplete="off"
                                                  ref={this.epMobile}
                                                  onChange={this.updateMobileNumber}
                                                  autofocus
                                                  value={this.state.mobile}
                                                  maxLength="10"
                                                  maxlength="10" />
                                <span class="ep-error form-control-feedback" ref={this.epError}></span>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" type="password" placeholder="Password" name="password"
                                    ref={this.epPassword}
                                    value={this.state.password}
                                    onChange={this.updatePassword}
                                        />
                                    <span class="ep-error form-control-feedback" ref={this.epErrorPass}></span>
                                <Link to="/forgot" class="color-grey pull-right hint-text"> Forgot Password ?</Link>
                            </div>
                            <div class="ui checkbox">
                                <input type="checkbox" name="" />
                                <label class="form-check-label" for="gridCheck">
                                <p> Remember me</p>
                                </label>
                            </div>
                            <input type="submit" class="btn d-btn btn-primary full-width" value="Login" name="" />
                        </form>
                        <br />
                        <div class="text-center">
                            <br />
                            <p class="color-grey">Don't have an account? <Link to="/signup" class="d-link"> Create an account</Link></p>
                        </div>
                    </div>
                </div>
		</div>
           			
        );
    }
}

export default LoginForm;