import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {hasPermission} from '../../Utilities/Auth'
export default class AdminMenu extends Component{
    constructor(props){
        super(props);
        this.state = {
            dashboardActive : false,
            usersActive : false,
            godownActive : false,
            itemsActive : false,
            settingsActive : false,
            logoutActive : false,
        }
        // console.log(this.props.active);
      

    }
    componentDidMount(){
        switch(this.props.active){
            case 'Dashboard':
                this.setState({dashboardActive : true});
            break;
            case 'Users':
                this.setState({usersActive : true});
            break;
            case 'Godown':
                this.setState({godownActive : true});
            console.log(this.props.active);
            break;
            case 'Items':
                this.setState({itemsActive : true});
            break;
            case 'Technicians':
                this.setState({techniciansActive : true});
            break;
            case 'Settings':
                this.setState({settingsActive: true});
            break;
            case 'Logout':
                this.setState({logoutActive : true});
            break;
            default:
            break;

        }
        console.log(this.state.godownActive);
    }
    render(){

        var godownClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.godownActive
        });
        
        var dashboardClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.dashboardActive
        });

        var usersClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.usersActive
        });

        var customersClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.customersActive
        });


        var itemsClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.itemsActive
        });
        var techniciansClass = classNames('m-menu__item', this.props.className, {
            'm-menu__item--active': this.state.techniciansActive
            });
        
        var settingsClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.settingsActive
        });

        var logoutClass = classNames('m-menu__item', this.props.className, {
        'm-menu__item--active': this.state.logoutActive
        });

        return(
            <div class="m-grid__item m-body__nav">
                <div class="m-stack m-stack--ver m-stack--desktop">
                    <div class="m-stack__item m-stack__item--middle m-stack__item--fluid">
                        <button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light " id="m_aside_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
                        <div id="m_header_menu" class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light ">
                            <ul class="m-menu__nav  m-menu__nav--submenu-arrow ">

                                <li className={dashboardClass} aria-haspopup="true"><Link to="/dashboard" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Dashboard</span></Link></li>
                                { hasPermission('user') &&
                                    <li className={usersClass} aria-haspopup="true"><Link to="/users" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Users</span></Link></li>
                                }
                                  { hasPermission('user') &&
                                    <li className={customersClass} aria-haspopup="true"><Link to="/customers" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Customers</span></Link></li>
                                }
                                { hasPermission('godown') &&
                                    <li className={godownClass}  aria-haspopup="true"><Link to="/godowns" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Godown</span></Link></li>
                                }
                                { hasPermission('item') &&
                                <li className={itemsClass}  aria-haspopup="true"><Link to="/items" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Items</span></Link></li>
                                }
                                 { (hasPermission('technician') || hasPermission('technician-sale')) &&
                                <li className={techniciansClass}  aria-haspopup="true"><Link to="/technicians" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Technicians</span></Link></li>
                                }
                                {/* <li className={settingsClass}  aria-haspopup="true"><Link to="/settings" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Settings</span></Link></li> */}
                                <li className={logoutClass}  aria-haspopup="true"><Link to="/logout" class="m-menu__link "><span class="m-menu__item-here"></span><span class="m-menu__link-text">Logout</span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    )
    };
}