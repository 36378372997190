import axioss from 'axios';
import _ from 'lodash';
// const gToken = localStorage.getItem('tokenn');
let axios =  refreshAxios();
let URL = 'http://id.edutorial.in/api/api';
// let URL = 'http://127.0.0.1:8000/api';

function refreshAxios(){
    let ax =  axioss.create({
        baseURL: URL,
        timeout: 10000,
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    });
    ax.defaults.headers.common['Authorization'] = 'Bearer ' + getToken() ;
    ax.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    
    return ax;
}

function getToken(){
    return localStorage.getItem('eztoken');
}

function clearToken(){
    localStorage.removeItem('eztoken');
}

function login(mobile, password, cb){
    axios.post(URL+'/auth/login', {
        mobile: mobile,
        password: password
    })
        .then((response) => {
            // if( (response.data.roles.indexOf('superadmin') >= 0) 
            // || (response.data.roles.indexOf('godown') >= 0)
            // || (response.data.roles.indexOf('support') >= 0)){
                console.log(response);
                localStorage.setItem('eztoken', response.data.token);
                localStorage.setItem('ezroles', response.data.roles);
                localStorage.setItem('ezname', response.data.data.name);
                localStorage.setItem('ezmobile', response.data.data.mobile);
                localStorage.setItem('ezid', response.data.data.id);
    
                axios= refreshAxios();
                handleResponse(response, cb);

            // }else{
            //     let error = {
            //         response : {
            //             statusText : 'Unauthorized'
            //         }
            //     }
            //     handleError(error,cb);


            // }
        }).catch((error) => {
            handleError(error,cb);
        });
}

function signup(name, email, mobile, password, cb){
    axios.post(URL+'/auth/signup',{
        name: name,
        email : email,
        mobile : mobile,
        password : password
    }).then((response)=>{
        handleResponse(response, cb);
    }).catch((error) => {
        handleError(error,cb);

    });

}

function getStats(cb){
    axios.get(URL+'/stats/admin')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function assignGodown(godown_id, user_id, cb){
    axios.post(URL+'/godown/assign', {
        godown_id : godown_id,
        user_id : user_id
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getTechnicianSale(technician_id, date, cb){
    axios.post(URL+'/technician/sale', {
        technician_id : technician_id,
        date:date
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function approveSale(technician_inventory_sale_id, cb){
    axios.post(URL+'/technician/sale/approve', {
        id : technician_inventory_sale_id,
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getGodowns(cb){
    axios.get(URL+'/godown/list')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getMyGodowns(cb){
    axios.get(URL+'/my/godowns')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getGodown(id, cb){
    axios.get(URL+'/godown/'+id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function addGodown(name, address, active, cb){
    axios.post(URL+'/godown/add', {
        name : name,
        address : address,
        isActive : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function updateGodown(id, name, address, active, cb){
    axios.put(URL+'/godown/'+id, {
        name : name,
        address : address,
        isActive : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function deleteGodown(id, cb){
    axios.delete(URL+'/godown/'+id, {
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function getRoles(cb){
    axios.get(URL+'/role/list')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}


function getUsers(cb){
    axios.get(URL+'/user/list')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getUser(id, cb){
    axios.get(URL+'/user/'+id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function addUser(name, email, mobile, password, role_id, active, cb){
    axios.post(URL+'/user/add', {
        name : name,
        email : email,
        mobile : mobile,
        password : password,
        role_id : role_id,
        isActive : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function updateUser(id, name,  email, mobile, password, role_id, active, cb){
    axios.put(URL+'/user/'+id, {
        name : name,
        email : email,
        mobile : mobile,
        password : password,
        role_id : role_id,
        isActive : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function deleteUser(id, cb){
    axios.delete(URL+'/user/'+id, {
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function getCustomers(cb){
    axios.get(URL+'/user/list')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getCustomer(id, cb){
    axios.get(URL+'/user/'+id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function addCustomer(name, email, mobile, password, role_id, active, cb){
    axios.post(URL+'/user/add', {
        name : name,
        email : email,
        mobile : mobile,
        password : password,
        role_id : role_id,
        isActive : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function updateCustomer(id, name,  email, mobile, password, role_id, active, cb){
    axios.put(URL+'/user/'+id, {
        name : name,
        email : email,
        mobile : mobile,
        password : password,
        role_id : role_id,
        isActive : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function deleteCustomer(id, cb){
    axios.delete(URL+'/user/'+id, {
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function getItems(cb){
    axios.get(URL+'/item/list')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getItem(id, cb){
    axios.get(URL+'/item/'+id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function addItem(name, image, cost, active, cb){
    axios.post(URL+'/item/add', {
        name : name,
        image : image,
        cost : cost,
        is_active : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function updateItem(id, name, image, cost, active, cb){
    axios.put(URL+'/item/'+id, {
        name : name,
        image : image,
        cost : cost,
        is_active : active=='on'?1:0
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function deleteItem(id, cb){
    axios.delete(URL+'/item/'+id, {
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function getGodownInventories(godown_id, cb){
    axios.get(URL+'/godown-inventory/list?godown_id='+godown_id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getGodownInventory(id, cb){
    axios.get(URL+'/godown-inventory/'+id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function addGodownInventory(challan_no, godown_id, item_id, item_quantity, cb){
    axios.post(URL+'/godown-inventory/add', {
        godown_id : godown_id,
        item_id : item_id,
        item_quantity : item_quantity,
        challan_no : challan_no
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function updateGodownInventory(id, challan_no, godown_id, item_id, item_quantity, cb){
    axios.put(URL+'/godown-inventory/'+id, {
        godown_id : godown_id,
        item_id : item_id,
        item_quantity : item_quantity,
        challan_no : challan_no
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function deleteGodownInventory(id, cb){
    axios.delete(URL+'/godown-inventory/'+id, {
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function getTechnicianInventories(technician_id, cb){
    axios.get(URL+'/technician-inventory/list?technician_id='+technician_id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getTechnicianInventory(id, cb){
    axios.get(URL+'/technician-inventory/'+id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function addTechnicianInventory(godown_id, technician_id, item_id, item_quantity, cb){
    axios.post(URL+'/technician-inventory/add', {
        technician_id : technician_id,
        godown_id : godown_id,
        item_id : item_id,
        item_quantity : item_quantity,
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function updateTechnicianInventory(id, godown_id, technician_id, item_id, item_quantity, cb){
    axios.put(URL+'/technician-inventory/'+id, {
        technician_id : technician_id,
        godown_id : godown_id,
        item_id : item_id,
        item_quantity : item_quantity,
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function deleteTechnicianInventory(id, cb){
    axios.delete(URL+'/technician-inventory/'+id, {
    })
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
            handleError(error, cb);
        });
}

function getTechnicians(cb){
    axios.get(URL+'/technician/list')
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}

function getTechnician(id, cb){
    axios.get(URL+'/technician/' + id)
        .then((response) => {
            handleResponse(response, cb);
        }).catch((error) => {
           handleError(error, cb);
        });
}



function validateOTP(type, value, code, cb){
    axios.post(URL+'/user/verify', {
        type: type,
        value: value,
        code : code
    })
        .then((response) => {
            if(response.data.data){
                // localStorage.setItem('eztoken', response.data.data['accessToken']);
                // axios= refreshAxios();
                handleResponse(response, cb);

            }else{
                handleResponse(response, cb);

            }
        }).catch((error) => {
            handleError(error, cb)
        });
}



function handleResponse(response, cb){
            console.log("resp",response);
    if(_.has(response, 'data')){
        if (_.has(response.data, 'status')) {
            if (response.data.status == 'fail') {
                //handle error
                if (_.has(response.data, 'errorData')) {
                    if (_.has(response.data.errorData, 'errorCode') &&
                        _.has(response.data.errorData, 'errorReason')) {
                        cb('error', response.data.errorData.errorCode + ': ' + response.data.errorData.errorReason);
    
                    }
                }
            } else if (response.data.status == 'ok') {
                //handle success
                if (_.has(response, 'data')) {
                    cb('success', response.data);
                }
            }
        }
    }
}

function handleError(error, cb){
    console.log("err", error);
        console.log(error.response);
        // console.log(_.has(error, 'message'));
        // console.log(_.has(error, 'response'));
    
    if(_.has(error, 'response')){
        if(_.has(error.response, 'statusText')){
            var errorMsg = error.response.statusText;
            switch (errorMsg) {
                case 'Unauthorized':
                    clearToken();
                    window.location= '/';
                    cb('error', 'Invalid Token');
                    break;
                case 'Bad Request':
                    cb('error', 'Bad Request');
                    break;
                case 'Network Error':
                    console.log(error.message);
                    cb('error', 'Couldn\'t connect to Internet');
                    break;
                case 'Unprocessable Entity':
                    cb('error', error.response.data.error.errors);
                    break;
                default:
                    cb('error', 'An error occured');
                    break;
            }
        }   else if (_.has(error, 'message')) {
            console.log(error);
            var errorMsg = error.message;
            switch (errorMsg) {
                case 'Network Error':
                    console.log(error.message);
                    cb('error', 'Couldn\'t connect to Internet');
                    break;

                default:
                    cb('error', 'An error occured');
                    break;
            }
        }
    } 
    


    
}




export { axios, getToken, refreshAxios, login, signup, 
    validateOTP, 
    getStats,
    getRoles,
};