import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';
import {validateOTP} from '../../../Axios/Axios'


class EmailForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            email : '',
            password: '',
            alertTitle : '',
            code:''
        }
        this.updateCode = this.updateCode.bind(this);
        this.validate = this.validate.bind(this);
        this.epError = React.createRef();
        this.epCode = React.createRef();
    }

    componentDidMount(){
    }

    updateCode(e){
        this.setState({code : e.target.value});

    }
   
   

    validate(e){
        e.preventDefault();
        let code = this.state.code;
        if(code.length > 0){
            // console.log(email);
            let email = localStorage.getItem('email');
            this.epError.current.textContent = '';
            validateOTP('email', email, code, (response) => {
                console.log(response)
                this.props.history.push('/login');
            })
        }else{
            this.epError.current.textContent = 'Invalid Code';
            return false;
        }
    }


    render(){
        return(
            <div class="container">
            <div class="login-container">
                <div class=" d-box">
                    <div class="text-center">
                        <h3>Validate Email</h3>
                        <span class="color-grey">Enter code received on your email</span>
                    </div>
                    <br />
                    <br />
                    <form class="d-form" action="" onSubmit={this.validate}>
                        <div class="form-group">
                        <input type="text" class="form-control"  type="text" placeholder="Code" name="code" autocomplete="off"
                                                  ref={this.epCode}
                                                  onChange={this.updateCode}
                                                  autofocus
                                                  maxLength="4"
                                                  maxlength="4" />
                            <span class="ep-error form-control-feedback" ref={this.epError}></span>
                        </div>
                        <input type="submit" class="btn d-btn btn-primary full-width" value="Submit" name="" />
                    </form>
                    <br />
                    <div class="text-center">
                        <br />
                        {/* <p class="color-grey"><Link to="/signup" class="d-link"> Go Back </Link></p> */}
                    </div>
                </div>
            </div>
    </div>		
        );
    }
}

export default EmailForm;